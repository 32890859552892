<template>
    <div>
        <div>
            <el-image
                v-if="getAdvData(42).children"
                style="width: 100%; height: 50vh; cursor: pointer"
                :src="getAdvData(42).children[0].image"
                fit="cover"
                @click="open(getAdvData(42).children[0].url)"
            ></el-image>
        </div>
        <div>
            <el-row style="margin: 50px 0 100px">
                <h1 style="font-weight: 400; margin: 50px 0">威有展行业痛点</h1>
                <el-row>
                    <el-col :span="8" v-for="(item, i) in iconArr" :key="i">
                        <div class="imgs">
                            <img :src="item.url" :class="`img_item${i}`" />
                        </div>
                        <p style="font-size: 20px; font-weight: 100; line-height: 2">
                            {{ item.mark }}
                        </p>
                        <div style="width: 230px; margin: 0 auto">
                            <p style="white-space: pre-wrap; font-size: 14px; opacity: 0.8; line-height: 2">
                                {{ item.cont }}
                            </p>
                        </div>
                    </el-col>
                </el-row>
            </el-row>
        </div>
        <div style="width: 100%; background-color: rgba(226, 234, 253, 18%)">
            <el-row style="padding: 100px">
                <el-col :span="10">
                    <div style="text-align: left">
                        <h1 style="font-weight: 400; margin-bottom: 20px">VR展厅<br />将线下展厅搬至线上</h1>
                        <ul style="width: 80%; list-style-type: decimal; margin-left: 20px; line-height: 1.8">
                            <li>以三维重建技术对展厅1：1完整复刻，1.34亿像素超高清画质充分还原展厅细节</li>
                            <li>自研激光VR采集设备伽罗华，可支持超大空间采集，高效完成整个展区三维重建</li>
                            <li>支持多媒体无痕嵌入、路线引导、语音讲解等功能，让用户获得媲美线下的观展体验</li>
                        </ul>
                        <p style="margin: 20px 0 10px">相关产品</p>
                        <template v-for="el in topList">
                            <div :key="el.id" @click="goDetail(el)" class="card" style="" shadow="hover" :title="el.title">
                                <img width="90%" v-lazy="el.little_image" />
                                <!-- <p style="font-size: 1px; position: absolute; margin-left: -5px">{{  }}</p> -->
                            </div>
                        </template>
                    </div>
                </el-col>
                <el-col :span="14">
                    <iframe
                        style="width: 100%; height: 65vh; border: none; border-radius: 5px"
                        src="https://realsee.cn/ZrQQwY2L?entry=share"
                    ></iframe>
                </el-col>
            </el-row>
        </div>
        <div>
            <el-row style="padding: 100px 0">
                <el-col :span="14">
                    <el-image
                        style="border-radius: 15px; width: 90%; float: right"
                        src="https://iv.okvu.cn/vugw/img/159ceea6-53ef-4031-d952-807d04a1c443.png"
                        fit="cover"
                    ></el-image>
                </el-col>
                <el-col :span="10">
                    <div style="text-align: left; margin: 80px">
                        <h1 style="font-weight: 400; margin-bottom: 20px">虚拟展厅<br />大幅节省搭建成本</h1>
                        <ul style="width: 80%; list-style-type: decimal; margin-left: 20px; line-height: 1.8">
                            <li>通过3D物品建模能力，结合虚拟空间建造，可打造逼真宛如实景的虚拟展厅</li>
                            <li>突破线下展会的时空限制的同时，大幅降低线下搭建的人工、场地、物料成本</li>
                            <li>参展单位可在虚拟空间内，充分展示商品，实现信息的高密度传播，24小时不打烊</li>
                        </ul>
                    </div></el-col
                >
            </el-row>
        </div>
        <div>
            <el-row style="padding: 100px 0">
                <el-col :span="10">
                    <div style="text-align: left; margin: 80px">
                        <h1 style="font-weight: 400; margin-bottom: 20px">多终端适配，提升趣味性</h1>
                        <p>不仅可以通过手机、pad、pc等沉浸式漫游，还可以适配电视大屏及VR眼镜等 终端</p>
                    </div></el-col
                >
                <el-col :span="14">
                    <el-image
                        style="border-radius: 15px; width: 90%; float: left"
                        src="https://iv.okvu.cn/vugw/img/7e28ee22-962e-4b37-6eee-bf9b92e44b4e.jpg"
                        fit="cover"
                    ></el-image>
                </el-col>
            </el-row>
        </div>
        <div>
            <el-row style="padding: 100px 0">
                <el-col :span="14">
                    <el-image
                        style="border-radius: 15px; width: 90%; float: right"
                        src="https://iv.okvu.cn/vugw/img/db12dc52-7ed5-483a-44a0-17d88eadc69b.png"
                        fit="cover"
                    ></el-image>
                </el-col>
                <el-col :span="10">
                    <div style="text-align: left; margin: 80px">
                        <h1 style="font-weight: 400; margin-bottom: 20px">VR场景内直接交互<br />随时随地完成交易</h1>
                        <ul style="width: 80%; list-style-type: decimal; margin-left: 20px; line-height: 1.8">
                            <li>VR云展厅与虚拟展厅均支持同屏语音带逛服务，多人在线洽谈，随时随地完成交易</li>
                            <li>一键分享社交媒体，大幅提升展会的关注度，塑造品牌IP</li>
                        </ul>
                    </div></el-col
                >
            </el-row>
        </div>
        <div style="padding-top: 100px; background: rgb(247, 249, 252)">
            <h1 style="font-weight: 400; margin: 0 0 20px">相关客户案例</h1>
            <ul class="footeList">
                <li v-for="el in bottomList" :key="el.id">
                    <div @click="goDetail(el)">
                        <img v-lazy="el.little_image" />
                    </div>
                    <p>{{ el.title }}</p>
                </li>
                <div class="kong">
                    <span @click="$router.push('/case?id=39&fuid=7')">查看更多案例</span>
                </div>
            </ul>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    data() {
        return {
            iconArr: [
                {
                    url: "https://iv.okvu.cn/vugw/img/vu1111.png",
                    mark: "线下发展出不确定性",
                    cont: "外界因素的不确定性，造成线下展出大量取消",
                },
                {
                    url: "https://iv.okvu.cn/vugw/img/vu1111.png",
                    mark: "缺乏对用户的有效吸引",
                    cont: "传统展出方式千篇一律，难以为用户带来耳目一新的观展体验",
                },
                {
                    url: "https://iv.okvu.cn/vugw/img/vu1111.png",
                    mark: "难以快速提升知名度",
                    cont: "难以在社交媒体平台快速裂变，提升用户感知",
                },
            ],
            topList: [],
            bottomList: [],
        };
    },
    inject: ["getAdvData"],
    created() {
        axios.get("/api/project/vr").then(({ data }) => {
            this.topList = data;
        });
        axios.get("/api/project/vrbottom").then(({ data }) => {
            this.bottomList = data;
        });
    },
    methods: {
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            this.$router.push({
                name: "listDetail",
                params: { id },
            });
        },
        open(url) {
            window.open(url, "_blank");
        },
    },
};
</script>
<style scoped lang="less">
.small {
    background: red;
}
.imgs {
    margin-bottom: 10px;
    width: 60px;
    height: 60px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    & > img {
        position: absolute;
        width: 336px;
    }
    .img_item0 {
        top: -269px;
        left: 0px;
    }
    .img_item1 {
        top: -340px;
        left: -125px;
    }
    .img_item2 {
        top: -269px;
        left: -62px;
    }
}
.footeList {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    height: 540px;
    overflow: hidden;
    li {
        width: 25%;
        position: relative;
        & > div {
            // position: absolute;
            width: 90%;
            height: 200px;
            margin: 0 auto;
            overflow: hidden;
            border-radius: 20px;
            cursor: pointer;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: 0.5s;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
        & > p {
            margin-top: 10px;
            height: 30px;
            line-height: 30px;
        }
    }
    .kong {
        position: absolute;
        width: 100%;
        height: 100px;
        line-height: 100px;
        font-size: 24px;
        bottom: 0;
        left: 0;
        background: linear-gradient(transparent, rgb(247, 249, 252));
        span:hover {
            color: #7781f1;
            cursor: pointer;
        }
    }
}
.card {
    width: 15%;
    border: none;
    display: inline-block;
    cursor: pointer;
    margin-right: 1%;
    border-radius: 10px;
    overflow: hidden;
    padding: 20px;
    transition: 0.5s;
    &:hover {
        box-shadow: 1px 1px 10px 1px gainsboro;
    }
}
</style>
